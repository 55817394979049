
  import { Component, Mixins, Prop, Emit, Watch } from "vue-property-decorator";
  import { ValidationProvider } from "vee-validate";
  import { HasProperyPart } from "@/mixins/has-property-part";

  @Component({
      components: {
          InputRadio: () => import("@/components/forms/property-radio.vue"),
          ValidationProvider,
      },
  })
  export default class ProperyPartInstallationVentilation extends Mixins(HasProperyPart) {
      @Prop({ required: true }) value?: ProperyInstallationVentilation;

      localValue: ProperyInstallationVentilation = {
          has_ventilation_system: null,
          system_grade: null,
      };

      resetValues() {
          this.localValue.system_grade = null;
          this.handleInput();
      }

      @Emit("input")
      handleInput() {
          return this.localValue;
      }

      @Watch("value", { immediate: true })
      valueChanged(newValue: ProperyInstallationVentilation) {
          this.localValue = newValue;
      }
  }
